/* General global styles */

html,
body,
#root {
  font-family: 'Roboto', sans-serif;
  height: 100%;
  margin: 0;
  padding: 0;
}

.ms-welcome {
  padding: 20px;
  margin: 0 auto;
  border-radius: 8px;
}

/* Typography */

h1, h2, h3, h4, h5, h6 {
  margin: 0;
}

p {
  line-height: 1.6;
}

/* Button styles */

button,
.MuiButton-root {
  padding: 8px 16px;
  border-radius: 4px;
  border: none;
  text-transform: uppercase;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

button:hover,
.MuiButton-root:hover {
  background-color: #1565c0;
}
